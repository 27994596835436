import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DayPipe } from '../../../pipe/dayPipe.pipe';
import { WeekDayPipe } from '../../../pipe/weekday.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { Event } from 'src/app/services/hobbyts.service';
import { FeatureFlagDirective } from 'src/app/shared/feature-flag.directive';
import moment from 'moment';
import { calculateChatName } from 'src/app/utils/profile.utils';

@Component({
  selector: 'hb-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    TitleCasePipe,
    DatePipe,
    WeekDayPipe,
    DayPipe,
    FeatureFlagDirective,
    CommonModule
  ]
})
export class EventCardComponent implements OnInit {
  @Output() public onEventClick = new EventEmitter<string>();
  @Input() public event!: Event;
  @Input() public isEventDetail: any;
  public isSoon = false;
  public playerNeeded: string;
  public telegramMessageLink: string;

  constructor(private router: Router) { }

  public ngOnInit(): void {
    const eventDateTime = this.parseEventDateTime(this.event.startDate, this.event.startTime);
    const currentTime = moment();

    const timeDifference = eventDateTime.diff(currentTime);

    this.isSoon = timeDifference <= 7200000 && timeDifference >= 0; // 2 hours = 7200000 ms

    this.playerNeeded = this.event.playerLimit + $localize` left`;

    this.telegramMessageLink = calculateChatName(this.event.messageId?.[0] ?? null).telegramMessageLink;
  }

  public onEditClick(id: string): void {
    this.router.navigate(['edit-event', id]);
  }

  private parseEventDateTime(startDate: string, startTime: string): moment.Moment {
    // Parse the start date and time to a moment object
    const date = moment(+startDate);
    const [hourPart, minutePart] = startTime.split(':');
    const [minute, period] = minutePart.split(' ');

    // Adjust for 12-hour format
    let hour = parseInt(hourPart, 10);
    if (period === 'PM' && hour < 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;

    date.hours(hour).minutes(parseInt(minute, 10));

    return date;
  }
}
