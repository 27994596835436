@if (event) {
<mat-card (click)="onEventClick.emit(event!.id)">
  <mat-card-header class="align-center">
    <div mat-card-avatar class="event-avatar"></div>
    <mat-card-title>{{ isEventDetail ? (event.startDate | weekDay | date: 'EEEE' | titlecase) + ', ' : '' }}
      <span [ngClass]="{ 'green-start-time': isSoon }">{{ event.startTime! | dayPipe | date: 'shortTime' }} {{
        event.endTime ? '-' : '' }}
        {{ event.endTime! | dayPipe | date: 'shortTime' }}</span>
    </mat-card-title>
    <mat-card-subtitle *hbFeatureFlag="true">{{ event.joinedPlayer! || 0 }}{{ event.teamSize ? "-" + event.teamSize +
      "v" + event.teamSize : '' }}</mat-card-subtitle>
    @if (event.isFull) {
    <span class="event-status-full" i18n>Full</span>
    } @else if(event.playerLimit) {
    <span class="event-status-open">{{ playerNeeded }}</span>
    } @else {
    <span class="event-status-open" i18n>Open</span>
    }
    <ng-container *hbFeatureFlag="true">
      @if (!isEventDetail) {
      <mat-icon class="va-middle">chevron_right</mat-icon>
      } @else if (event.isOwner) {
      <mat-icon class="va-middle cursor-pointer" svgIcon="edit" (click)="onEditClick(event!.id)"></mat-icon>
      }
    </ng-container>
    <ng-container *hbFeatureFlag="false">
      <mat-icon class="va-middle cursor-pointer" svgIcon="edit" (click)="onEditClick(event!.id)"></mat-icon>
    </ng-container>
  </mat-card-header>
  <mat-card-content>
    <div class="flex-col">
      <p class="description">
        {{ event.description! }}
      </p>
      @if (isEventDetail && event.messageId?.length) {
        <a [href]="telegramMessageLink" target="_blank" class="tg-event-link">
          <span>To the event in TG</span>
          <mat-icon svgIcon="telegram"></mat-icon>
        </a>
      }
    </div>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
}